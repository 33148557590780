import React, { useEffect } from 'react'
import { useState } from 'react'
import StoreRouteScheduleManager from './StoreRouteScheduleManager'


export default function WeekdayTabContainer({upcomingLaunches, drivers, locations, store, upcomingLaunchesLoading, fetchRouteContainerAPI, unscheduledRouteContainerLoading, setUnscheduledRouteContainerLoading, routeTemplates, setRouteTemplates, weekday, setWeekday}) {
    const [unscheduledTasks, setUnscheduledTasks] = useState([])
    const [unscheduledTasksLoading, setUnscheduledTasksLoading]= useState(false)

    async function fetchUnscheduledTasksAPI() {

           setUnscheduledTasksLoading(true)
  
           let response = await fetch(`/api/launches/?day_of_week=${weekday}&parent_location_id=${store.id}&schedule_status=unscheduled`)

           response = await response.json()

           setUnscheduledTasks(response.launches)
           setUnscheduledTasksLoading(false)
    }
   
    useEffect(() => {
        fetchUnscheduledTasksAPI()
    }, [store, weekday])
   
    const sunLaunch = []
    const monLaunch = []
    const tueLaunch = []
    const wedLaunch = []
    const thursLaunch = []
    const friLaunch = []
    const satLaunch = []

    // for the draggable unscheduledTasks
    const sunUnscheduledTasks = []
    const monUnscheduledTasks = []
    const tueUnscheduledTasks = []
    const wedUnscheduledTasks = []
    const thursUnscheduledTasks = []
    const friUnscheduledTasks = []
    const satUnscheduledTasks = []

     
    upcomingLaunches.map((upcomingLaunch) => {
        const launch = new Date(upcomingLaunch.location.launch_date).getDay()
        if (launch === 6) {
            sunLaunch.push(upcomingLaunch)
        } else if (launch === 0) {
            monLaunch.push(upcomingLaunch)
        } else if (launch === 1) {
            tueLaunch.push(upcomingLaunch)
        } else if (launch === 2) {
            wedLaunch.push(upcomingLaunch)
        } else if (launch === 3) {
            thursLaunch.push(upcomingLaunch)
        } else if (launch === 4) {
            friLaunch.push(upcomingLaunch)
        } else if (launch === 5) {
            satLaunch.push(upcomingLaunch)
        }
    })
   
    unscheduledTasks.map((unscheduledTask) => {
       
        const launch = new Date(unscheduledTask.location.launch_date).getDay()
        if (unscheduledTask.drop_days.includes("monday")){
            monUnscheduledTasks.push(unscheduledTask)
            
        }
        if (unscheduledTask.drop_days.includes("tuesday")){
            tueUnscheduledTasks.push(unscheduledTask)
            
        }
        if (unscheduledTask.drop_days.includes("wednesday")){
            wedUnscheduledTasks.push(unscheduledTask)
        }
        if (unscheduledTask.drop_days.includes("thursday")){
            thursUnscheduledTasks.push(unscheduledTask)
            
        }
        if (unscheduledTask.drop_days.includes("friday")){
            friUnscheduledTasks.push(unscheduledTask)
            
        }
        if (unscheduledTask.drop_days.includes("saturday")){
            satUnscheduledTasks.push(unscheduledTask)
            
        }
        if (unscheduledTask.drop_days.includes("sunday")){
            sunUnscheduledTasks.push(unscheduledTask)
            
        }
    })

    const weekdays = [
        { day: "monday", unscheduledCount: monUnscheduledTasks.length, upcomingCount: monLaunch.length },
        { day: "tuesday", unscheduledCount: tueUnscheduledTasks.length, upcomingCount: tueLaunch.length },
        { day: "wednesday", unscheduledCount: wedUnscheduledTasks.length, upcomingCount: wedLaunch.length },
        { day: "thursday", unscheduledCount: thursUnscheduledTasks.length, upcomingCount: thursLaunch.length },
        { day: "friday", unscheduledCount: friUnscheduledTasks.length, upcomingCount: friLaunch.length },   
        { day: "saturday", unscheduledCount: satUnscheduledTasks.length, upcomingCount: satLaunch.length },
        { day: "sunday", unscheduledCount: sunUnscheduledTasks.length, upcomingCount: sunLaunch.length }
    ]


    const handleClick = e => {
        setWeekday(e.target.value)
    }

    function updateUnscheduledTasks(){
        fetchUnscheduledTasksAPI();
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "space-around", width: "80%", margin: "auto", padding: "1%"}}>
                {weekdays.map(dayOfWeek => {
                    return (
                        <div key={dayOfWeek.day}>
                            <button
                            value = {dayOfWeek.day}
                            className= {weekday === dayOfWeek.day ? "btn btn-default" : "btn"}
                            onClick={handleClick}
                            style={{
                                border: "1px solid #c0c2c2",
                                color: "black",
                                fontSize: "1.2rem",
                                width: "11rem",
                            }}
                            >
                            {dayOfWeek.day.toUpperCase()}
                            </button>
                            <p style={{
                                textAlign: "center",
                                marginBottom: "0%",
                                marginTop: "1%",
                                fontSize: "1.1rem",
                                fontWeight: "bold",
                                color: "red"
                            }}>{!dayOfWeek.upcomingCount ? <br></br> : (dayOfWeek.upcomingCount + " launching") }</p> 
                            <p style={{
                                textAlign: "center",
                                marginBottom: "0%",
                                fontSize: "1.1rem",
                                fontWeight: "bold"
                            }}>{dayOfWeek.unscheduledCount} unscheduled</p>
                        </div>
                    )
                })}
            </div>
            <StoreRouteScheduleManager updateUnscheduledTasks={updateUnscheduledTasks} upcomingLaunches={upcomingLaunches} unscheduledTasks = {unscheduledTasks} drivers={drivers} locations={locations} store={store} unscheduledTasksLoading={unscheduledTasksLoading} upcomingLaunchesLoading={upcomingLaunchesLoading} fetchRouteContainerAPI={fetchRouteContainerAPI} unscheduledRouteContainerLoading={unscheduledRouteContainerLoading} setUnscheduledRouteContainerLoading={setUnscheduledRouteContainerLoading} routeTemplates={routeTemplates} weekday={weekday}></StoreRouteScheduleManager>
        </div>
    )
}
