import React from 'react'
import { useState } from 'react';

import { Draggable } from 'react-beautiful-dnd'

import EditTaskPayoutForm from './EditTaskPayoutForm';

export default function TaskCard({ task, index, isDragDisabled, displayDays, column, updateRouteTemplate}) {
  const [closePayoutModal, setClosePayoutModal] = useState(true)

  let daysOfWeek = task.outpost_setting.drop_days;
  let days = displayDays(daysOfWeek)

  let launchDate = new Date(task.launch_date).toUTCString().slice(0, 17)
  const todaysDate = new Date().toISOString().slice(0, 10)
  const launch = new Date(task.launch_date).toISOString().slice(0, 10)

  const togglePayoutModal = (e) => {
    e.preventDefault()
    setClosePayoutModal(!closePayoutModal)
  }
  
  return (
    <div>
      <Draggable draggableId={task.outpost_setting_id} index={index} isDragDisabled={isDragDisabled}>
        {(provided) => (
          <div className= { task.incomplete_task ? 'panel panel-red' : 'panel panel-margin-skinny panel-default'}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div  style = {{ cursor: isDragDisabled ? "not-allowed" : null }}  className= "h-auto mh-110px" >
              <p style={{ fontWeight: "bold", fontSize: "1.2rem", padding: "4% 0 0 4%", lineHeight: "12px" }}>{task.location.name}</p>
              <div style={{ lineHeight: "6px", padding: "0 0 0 4%"}}>
                <p style={{ fontSize: "1.2rem"}}>{days} | Gravy ID: {task.location ? task.location.gravy_id : ""}</p>
                <p style={{ fontSize: "1.2rem" }}><span style={{ fontWeight: "bold", fontSize: "1.2rem"}}>Cutoff Time: </span> {task.complete_after ? new Date(2000, 1, 1, task.complete_after.hour, task.complete_after.minute ).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : null}</p>
                <p style={{ fontSize: "1.2rem" }}><span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Delivery Time: </span> {task.complete_before ? new Date(2000, 1, 1, task.complete_before.hour, task.complete_before.minute).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : null}</p>
                {column.id == 0 || typeof task.id == 'undefined' ? <></> : <p style={{ fontSize: "1.2rem" }}><span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Payout Amount: </span>{task.payout_amount} <a href="#" value={task.id} onClick={togglePayoutModal} data-toggle="modal" data-target={`#edit-payout-modal-${task.id}`} data-backdrop="static" data-keyboard="false" style={{ color: "#0047AB", backgroundColor: "#f9fafc", lineHeight: "6px" }} className="fa fa-pencil" ></a></p>}
                <p style={{ color: launch >= todaysDate ? "green" : "black", fontSize: "1.2rem" }}><span style={{ fontWeight: "bold", fontSize: "1.2rem"}}>Launches: </span>{launchDate}</p>
              </div>
            </div>
          </div>
        )}
      </Draggable>
      <EditTaskPayoutForm closePayoutModal={closePayoutModal} setClosePayoutModal={setClosePayoutModal} task={task} column={column} updateRouteTemplate={updateRouteTemplate}/>
    </div>
  )
}
