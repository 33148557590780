import React, { useState, useEffect } from 'react'
import DaysOfWeek from './DaysOfWeek';

export default function AddNewRouteScheduleForm(props) {
  const defaultPickupInstructions = "Please enter front of store and check in with a team member at the store that you are picking up for outposts. Make sure you have all outpost bags that match the names on Onfleet and the quantity needed for each outpost from Onfleet. "
  const [day, setDay] = useState(props.weekday);
  const [restaurant, setRestaurant] = useState(props.store.id);
  const [driver, setDriver] = useState();
  const [pickupTime, setPickupTime] = useState('');
  const [payout, setPayout] = useState('');
  const [instructions, setInstructions] = useState(defaultPickupInstructions);
  const [errors, setErrors] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [backendErrors, setBackendErrors] = useState('');

  useEffect(()=> {
    setDay(props.weekday);
    setRestaurant(props.store.id);
  }, [props.weekday, props.store])

  function handleCloseModal() {
    $('#add-new-route-modal').modal('hide')
  }

  const postData = async () => {

    const day_of_week = day
    const location_id = restaurant
    const driver_id = driver
    const payout_amount = payout
    const complete_after = pickupTime
    const pickup_instructions = instructions

    const template = {
      day_of_week,
      location_id,
      driver_id,
      payout_amount,
      complete_after,
      pickup_instructions
    }

    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    if (Object.keys(errors).length === 0) {
      const response = await fetch('/api/route_templates', {

        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',

        headers: {
          'Content-Type': 'application/json',

          "X-CSRF-Token": csrfToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(template)
      });

      let result;

      try {
        result = await response.json();
  
      }
      catch {
        setBackendErrors("Failed to parse JSON")
        return
      }

      if (response.status >= 400) {
        setBackendErrors(response.status)
        return
      }
      props.fetchRouteContainerAPI();
      return result;

    }

  }

  useEffect(() => {
    let newErrors = {};

    if ((day === "Select Day" || !day)) {
      newErrors = { ...newErrors, day: 'Choose a day' };
    }

    if (restaurant === "Select Restaurant" || !restaurant) {
      newErrors = { ...newErrors, restaurant: 'Choose a location' };
    }

    if (!pickupTime) {
      newErrors = { ...newErrors, pickupTime: 'Choose a pickup time' };
    }

    if (parseInt(payout, 10).toString() !== payout) {
      newErrors = { ...newErrors, payout: 'Input a payout amount in whole dollars' };
    }

    setErrors(newErrors);
  }, [day, restaurant, pickupTime, payout]);

  const handleSubmitDuplicate = (e) => {

    e.preventDefault();
    setSubmitted(true);

    if (Object.keys(errors).length === 0) {

      postData();

      setSubmitted(false);
      setDay('');
    }

  }

  const handleSubmit = (e) => {

    e.preventDefault();
    setSubmitted(true);

    if (Object.keys(errors).length === 0) {
      postData();

      setSubmitted(false);
      setDriver('');
      setPickupTime('');
      setPayout('');

      props.setCloseModal(true);
      handleCloseModal();
    }

  };

  return (
    <>
      {!props.closeModal &&
        <div className="modal" role="dialog" id="add-new-route-modal" style={{ width: "25%", height: "60%", margin: "auto", backgroundColor: "#E5E5E5", borderRadius: "10px" }}>
          <div style={{ padding: "0 0 0 5%" }}>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ margin: "2% 2% 0 0" }}>
              <span aria-hidden="true">&times;</span>
            </button>

            <form className="modal-body" style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "left" }}>
              <h3>Add New Route </h3>
              <span style={{ color: "red" }}>{backendErrors}</span>
              <label>
                Day of the Week*

                <select value={day} onChange={e => setDay(e.target.value)} className="form-control">
                  <DaysOfWeek />
                </select>

                {submitted && errors.day ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.day}</span> : null}
              </label>

              <label>
                Restaurant/Home Store*
                <select value={restaurant} onChange={e => setRestaurant(e.target.value)} className="form-control">
                  <option value="">Select Restaurant</option>
                  {props.locations.map(type => {
                    return <option key={type.id} value={type.id} label={type.name}> </option>
                  })}
                </select>
                {submitted && errors.restaurant ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.restaurant}</span> : null}
              </label>

              <label>
                Driver
                <select value={driver} onChange={e => setDriver(e.target.value)} className="form-control">
                  <option value="">Select Driver</option>
                  {props.drivers.map(driver => {
                    return <option key={driver.id} value={driver.id} label={driver.name}> </option>
                  })}
                </select>
              </label>

              <label>
                Pickup Time*
                <input type="time" value={pickupTime} onChange={e => setPickupTime(e.target.value)} className="form-control" />
                {submitted && errors.pickupTime ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.pickupTime}</span> : null}
              </label>

              <label>
                Payout*
                <input type="text" value={payout} onChange={e => setPayout(e.target.value)} className="form-control" />
                {submitted && errors.payout ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.payout}</span> : null}
              </label>
              <p style={{ fontSize: "1rem" }}>*Input a payout amount in whole dollars</p>


              <label>
                Pickup Instructions
                <textarea type="text" value={instructions} onChange={e => setInstructions(e.target.value)} className="form-control" />
              </label>

              <div className="addNewRouteScheduleFormButtons">
                <button onClick={handleSubmitDuplicate} className="btn btn-default createDuplicate" style={{ width: "50%" }}> Create + Duplicate </button>
                <button onClick={handleSubmit} className="btn btn-default create" style={{ width: "50%" }}> Create </button>
              </div>

            </form>
          </div>
        </div>
      }
    </>
  )
}
