import React, { useState } from 'react';
import { SingleDatePicker } from "react-dates";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";

export default function ManuallyScheduleRouteForm({ closeScheduleRouteModal, setCloseScheduleRouteModal, column, updateRouteTemplate, weekday, setRouteCreationMsg}) {
  const [selectedDate, setSelectedDate] = useState(moment())
  const [calendarFocus, setCalendarFocus] = useState(true)

  const handleSelectedDateSubmit = (e) => {
    e.preventDefault()
    handleCloseModal()  
    sendSelectedDate()
  }

  const closeModalOnClick = () => {
    setSelectedDate("")
    setCloseScheduleRouteModal(true)
  }

  function handleCloseModal() {
    $(`#edit-schedule-route-modal-${column.id}`).modal('hide')
  }

  const sendSelectedDate = async () => {
    const selected_date = selectedDate.toString()

    const formattedValues= {
      selected_date
    }

    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    const response = await fetch(`/api/route_templates/${column.id}/schedule`, {

      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',

      headers: {
        'Content-Type': 'application/json',

        "X-CSRF-Token": csrfToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(formattedValues)
    });

    let result;

    try {
      result = await response.json();

      if (result.message && typeof result.success === 'boolean') {
        let messages = [result.success, result.message]
        setRouteCreationMsg(messages)
      } else {
        let messages = [false, "An error has occured during manual route creation. See logs."]
        setRouteCreationMsg(messages)
      }
    }
    catch {
      let messages = [false, "Internal Server Error. Manual Route Creation failed."]
      setRouteCreationMsg(messages)
    }

    setCloseScheduleRouteModal(!closeScheduleRouteModal)
    updateRouteTemplate()

    return result
  }

  const dateSelector = (date) => {
    setSelectedDate(date)
  }

  const focusedSelector = ({focused}) => {
    setCalendarFocus(focused)
  } 

  const blockCalendarDays = (momentDate) =>{
    var formDate = momentDate.format('dddd')
    const day = formDate.toLowerCase()
    if (day != weekday){
      return true
    }
  }

  return (
    <>
      {!closeScheduleRouteModal && <div className="modal" role="dialog" id={`edit-schedule-route-modal-${column.id}`} style={{ width: "20%", height: "60%", margin: "auto", backgroundColor: "#E5E5E5", borderRadius: "10px" }}>
        <div style={{ padding: "0 0 0 5%" }}>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ margin: "2% 2% 0 0" }} onClick={closeModalOnClick}>
            <span aria-hidden="true">&times;</span>
          </button>
          <form className="modal-body" style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "left" }}>
            <h3>Select A Date </h3>
              <SingleDatePicker
                date={selectedDate}
                onDateChange={dateSelector}
                focused={calendarFocus}
                onFocusChange={focusedSelector}
                id={`${column.id}`}
                isDayBlocked={blockCalendarDays}
                orientation={"vertical"}
              />
            <p style={{ fontSize: "1rem" }}>Select a Date that matches the day of the week for this route.</p>
            <div>
              <button onClick={handleSelectedDateSubmit} className="btn btn-default save" style={{ width: "50%" }}> Save </button>
            </div>
          </form>
        </div>
      </div>
      }
    </>
  )
}
