import React from 'react'

import TaskCard from './TaskCard'

import {Droppable} from 'react-beautiful-dnd'

export default function UnscheduledTaskContainer({column, tasks, displayDays, updateRouteTemplate }) {
    return (
        <div>
            <div className='row bg-white borderRadius-s boxShadow p1 m1 w-240px' style={{ backgroundColor: "#f9fafc",}}>
                {tasks != 0 ? <></> : <h5>All tasks scheduled! Yay!</h5>}
                <Droppable droppableId={column.id.toString()}>
                {(provided) =>(
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >                    
                            {tasks.map((task, index) => (<TaskCard key={task.outpost_setting_id} task={task} index={index} displayDays={displayDays} isDragDisabled={task.incomplete_task} column={column} updateRouteTemplate={updateRouteTemplate}> {index}</TaskCard>))}
                            {provided.placeholder}
                        </div>
                )}
                </Droppable>
            </div>
        </div>
    )
}
