import React, { useState } from 'react';

export default function EditTaskPayoutForm({ closePayoutModal, setClosePayoutModal, task, updateRouteTemplate }) {
  const [taskPayout, setTaskPayout] = useState(task.payout_amount)
  const [errorMsg, setErrorMsg] = useState("")

  const handlePayoutSubmit = (e) => {
    e.preventDefault()

    if (taskPayout != "" && taskPayout.match("^[0-9.]+$")) {
      sendTaskPayout()
      handleCloseModal()      
    } else {
      setErrorMsg("Value cannot be left blank and it must be a number.")
      setTaskPayout(task.payout_amount)
    }
  }

  const closeModalOnClick = () => {
    setTaskPayout(task.payout_amount)
    setClosePayoutModal(!closePayoutModal)
  }
  
  function handleCloseModal() {
    $(`#edit-payout-modal-${task.id}`).modal('hide')
  }

  const sendTaskPayout = async () => {
    const payout_amount = taskPayout

    const formattedValues= {
      payout_amount
    }

    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    const response = await fetch(`/api/task_templates/${task.id}`, {

      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',

      headers: {
        'Content-Type': 'application/json',

        "X-CSRF-Token": csrfToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(formattedValues)
    });

    let result;

    try {
      result = await response.json();
    }
    catch {
      console.log("Task Template update error")
      return
    }
  
    setClosePayoutModal(!closePayoutModal)
    updateRouteTemplate()

    return result
  }
  
  return (
    <>
      {!closePayoutModal && <div className="modal" role="dialog" id={`edit-payout-modal-${task.id}`} style={{ width: "25%", height: "60%", margin: "auto", backgroundColor: "#E5E5E5", borderRadius: "10px" }}>
        <div style={{ padding: "0 0 0 5%" }}>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ margin: "2% 2% 0 0" }} onClick={closeModalOnClick}>
            <span aria-hidden="true">&times;</span>
          </button>
          <form className="modal-body" style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "left" }}>
            <h3>Edit Payout Amount for {task.location.name}</h3>
            <label>
              Payout*
              <input type="text" value={taskPayout} onChange={e => setTaskPayout(e.target.value)} className="form-control" />
            </label>
            <p style={{ fontSize: "1rem" }}>*Input payout in dollars</p>
            {errorMsg ? <span style={{ color: "red", fontSize: "1.5rem" }}>{errorMsg}</span> : <></>}
            <div>
              <button onClick={handlePayoutSubmit} className="btn btn-default save" style={{ width: "50%" }}> Save </button>
            </div>
          </form>
        </div>
      </div>
      }
    </>
  )
}
