import React from "react";

export default function UpcomingLaunches({ upcomingLaunches, displayDays }) {
  const headers = ["Launch Date", "Outpost", "Cutoff Time", "Delivery Time", "Days of Week", "Address", "Gravy ID", "Courier"]
 
  return (
    <div style={{ fontSize: "1.2rem"}}>
      <h4 style={{ marginLeft: "14px" }}>Upcoming Launches:</h4>
      <div className='row bg-white m1 p1 borderRadius-s boxShadow' style={{ width: "90%", margin: "auto" }}>
        <table className="table">
          <thead>
            <tr>
              {headers.map((header, index) => {
                return <th key={index}>{header}</th>
              })}
            </tr>
          </thead>
          {upcomingLaunches.length === 0 ? <tbody><tr><td colSpan="8" style={{textAlign: "center"}}>Sorry, no upcoming launches!</td></tr></tbody>:  
          <tbody>
              
            {upcomingLaunches.map((upcomingLaunch, index) => {
              let daysOfWeek = upcomingLaunch.drop_days;
              let days = displayDays(daysOfWeek)

              const options = { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' }
              
              const launchDate = new Date(upcomingLaunch.location.launch_date).toUTCString().slice(0, 17)
              return <tr key={index}>
                <td>{launchDate}</td>
                <td>
                  {/* //need to change the first part of the URL */}
                  <a href={`https://admin.galleyfoods.com/sweetgreen/locations/${upcomingLaunch.location_id}`} target="_blank">
                    <div>
                      {upcomingLaunch.name}
                    </div>
                  </a>
                </td>
                <td>{upcomingLaunch.complete_after ? new Date(upcomingLaunch.complete_after).toLocaleTimeString('en-US', options) : null}</td>
                <td>{upcomingLaunch.complete_before ? new Date(upcomingLaunch.complete_before).toLocaleTimeString('en-US', options) : null}</td>
                <td>{days}</td>
                <td style={{ width: "25%" }}>{upcomingLaunch.address.street_1}{upcomingLaunch.address.street_2} {upcomingLaunch.address.city}, {upcomingLaunch.address.state} {upcomingLaunch.address.zip}</td>

                <td>{upcomingLaunch.location.gravy_id}</td>
                <td>{upcomingLaunch.courier_partner}</td>
              </tr>
            })}
          </tbody>}
        </table>
      </div>
    </div>
  )
}
