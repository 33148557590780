import React, { useState, useEffect } from 'react'
import DaysOfWeek from './DaysOfWeek';

export default function EditRouteScheduleForm({ drivers, locations, column, updateRouteTemplate }) {

  const [day, setDay] = useState(column.day_of_week ? column.day_of_week : "");
  const [restaurant, setRestaurant] = useState(column.location_id ? column.location_id : "");
  const [driver, setDriver] = useState(column.driver_id ? column.driver_id : "");
  const [pickupTime, setPickupTime] = useState(column.complete_after ? column.complete_after : "");
  const [payout, setPayout] = useState(column.payout_amount ? parseInt(column.payout_amount) : "");
  const [instructions, setInstructions] = useState(column.pickup_instructions ? column.pickup_instructions : "");
  const [errors, setErrors] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [backendErrors, setBackendErrors] = useState('');
  const [closeModal, setCloseModal] = useState(false);

  function handleCloseModal() {
    $(`#edit-route-modal-${column.id}`).modal('hide')
  }

  const editData = async () => {

    const day_of_week = day
    const location_id = restaurant
    const driver_id = driver
    const payout_amount = payout
    const pickup_instructions = instructions
    const complete_after = pickupTime

    const template = {
      day_of_week,
      location_id,
      driver_id,
      payout_amount,
      pickup_instructions,
      complete_after
    }

    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    const response = await fetch(`/api/route_templates/${column.id}`, {

      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',

      headers: {
        'Content-Type': 'application/json',

        "X-CSRF-Token": csrfToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(template)
    });

    let result;

    try {
      result = await response.json();
    }
    catch {
      setBackendErrors("Failed to parse JSON")
      return
    }

    if (response.status >= 400) {
      setBackendErrors(response.status)
      return
    }

    setCloseModal(true);
    updateRouteTemplate();
    setCloseModal(false);

    return result;
  }

  const deleteData = async () => {

    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    const response = await fetch(`/api/route_templates/${column.id}`, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        "X-CSRF-Token": csrfToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });

    if (response.status >= 400) {
      setBackendErrors(response.status)
      return
    }

    updateRouteTemplate();
    setCloseModal(true);

  }

  useEffect(() => {
    let newErrors = {};

    if ((day === "Select Day" || !day)) {
      newErrors = { ...newErrors, day: 'Choose a day' };
    }

    if (restaurant === "Select Restaurant" || !restaurant) {
      newErrors = { ...newErrors, restaurant: 'Choose a location' };
    }

    if (!pickupTime) {
      newErrors = { ...newErrors, pickupTime: 'Choose a pickup time' };
    }

    if (parseInt(payout, 10).toString() !== String(payout)) {
      newErrors = { ...newErrors, payout: 'Input a payout amount in whole dollars' };
    }

    setErrors(newErrors);
  }, [day, restaurant, pickupTime, payout]);

  const handleDelete = (e) => {

    e.preventDefault();

    deleteData();

    setSubmitted(false);
    setDay('');
    setRestaurant('');
    setDriver('');
    setPickupTime('');
    setPayout('');
    setInstructions('');

    handleCloseModal();

  };

  const handleSubmit = (e) => {

    e.preventDefault();
    setSubmitted(true);

    if (Object.keys(errors).length === 0) {
      editData()
      setSubmitted(false);
      setDay('');
      setRestaurant('');
      setDriver('');
      setPickupTime('');
      setPayout('');
      setInstructions('');

      handleCloseModal();
    }

  };

  return (
    <>
      {!closeModal &&
        <div className="modal" role="dialog" id={`edit-route-modal-${column.id}`} style={{ width: "25%", height: "60%", margin: "auto", backgroundColor: "#E5E5E5", borderRadius: "10px" }}>
          <div style={{ padding: "0 0 0 5%" }}>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ margin: "2% 2% 0 0" }}>
              <span aria-hidden="true">&times;</span>
            </button>

            <form className="modal-body" style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "left" }}>
              <h3>Edit Route </h3>
              <span style={{ color: "red" }}>{backendErrors}</span>
              <label>
                Day of the Week*
                <select value={day} onChange={e => setDay(e.target.value)} className="form-control">
                  <DaysOfWeek/>
                </select>
                {submitted && errors.day ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.day}</span> : null}
              </label>

              <label>
                Restaurant/Home Store*
                <select value={restaurant} onChange={e => setRestaurant(e.target.value)} className="form-control">
                  <option value="">Select Restaurant</option>

                  {locations.map(type => {
                    return <option key={type.id} value={type.id} label={type.name}> </option>
                  })}
                </select>
                {submitted && errors.restaurant ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.restaurant}</span> : null}
              </label>

              <label>
                Driver
                <select value={driver} onChange={e => setDriver(e.target.value)} className="form-control">
                  <option value="">Select Driver</option>
                  {drivers.map(driver => {
                    return <option key={driver.id} value={driver.id} label={driver.name}> </option>
                  })}
                </select>
              </label>

              <label>
                Pickup Time*
                <input type="time" value={pickupTime} onChange={e => setPickupTime(e.target.value)} className="form-control" />
                {submitted && errors.pickupTime ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.pickupTime}</span> : null}
              </label>

              <label>
                Payout*
                <input type="text" value={payout} onChange={e => setPayout(e.target.value)} className="form-control" />
                {submitted && errors.payout ? <span style={{ color: "red", fontSize: "1rem" }}>{errors.payout}</span> : null}
              </label>
              <p style={{ fontSize: "1rem" }}>*Input payout in dollars</p>


              <label>
                Pickup Instructions
                <textarea type="text" value={instructions} onChange={e => setInstructions(e.target.value)} className="form-control" />
              </label>

              <div className="eddNewRouteScheduleFormButtons">
                <button onClick={handleDelete} className="btn btn-default delete" style={{ width: "50%" }}> Delete Route </button>
                <button onClick={handleSubmit} className="btn btn-default save" style={{ width: "50%" }}> Save </button>
              </div>
            </form>
          </div>
        </div>

      }
    </>
  )
}
