import React, { useState } from 'react'

import UnscheduledTaskContainer from './UnscheduledTaskContainer'
import RouteContainer from './RouteContainer'
import UpcomingLaunches from './UpcomingLaunches'

import { DragDropContext } from 'react-beautiful-dnd'
import { useEffect } from 'react'


export default function StoreRouteScheduleManager({ upcomingLaunches, unscheduledTasks, store, drivers, locations, updateRouteTemplate, updateUnscheduledTasks, unscheduledTasksLoading, upcomingLaunchesLoading, routeTemplates, unscheduledRouteContainerLoading, fetchRouteContainerAPI, weekday}) {
    const [data, setData] = useState(null);
    const [routeCreationMsg, setRouteCreationMsg] = useState([])
    const [refreshReminderMsg, setRefreshReminderMsg] = useState("")

    useEffect(() => {
        const routeTemplate = {};
        const routeTemplateTasks = {};

        routeTemplates.forEach((rt) => {
            const task_templates = rt.task_templates.map(tt => ({
                ...tt,
                outpost_setting_id: tt.outpost_setting_id.toString(),
            }))
            const taskIdsArray = task_templates.map(tt => tt.outpost_setting_id);
            rt.task_templates.forEach(tt => {
                tt["outpost_setting_id"] = tt.outpost_setting_id.toString()
                tt["launch_date"] = tt.starts_at.slice(0, 10)
                routeTemplateTasks[tt.outpost_setting_id] = tt
            })
            routeTemplate[rt.id] = { ...rt, task_templates, taskIdsArray }

        })
        
        const unscheduledTasksObj = {}
        const launchTaskIds = []
        // this creates our unscheduledTasksObject
        unscheduledTasks.map((unscheduledTask) => {

            const unscheduledTaskObj = {}
            const task_id = unscheduledTask.id.toString()
            unscheduledTaskObj.outpost_setting_id = task_id
            unscheduledTaskObj.launch_date = unscheduledTask.location.launch_date
            //name is in a location object to match the route_templates object from API

            unscheduledTaskObj.location = {}
            if (unscheduledTask.location != null) {
                unscheduledTaskObj.location["name"] = unscheduledTask.name
                unscheduledTaskObj.location["gravy_id"] = unscheduledTask.location.gravy_id
            }
            
            if(unscheduledTask.complete_after == null || unscheduledTask.complete_before == null){
                unscheduledTaskObj["incomplete_task"] = true
            }else{
                unscheduledTaskObj["incomplete_task"] = false
            }
            
            //separated by hour, minute, second, and second_of_day to match the API response
            unscheduledTaskObj.complete_after = {}
            if (unscheduledTask.complete_after != null) {
                unscheduledTaskObj.complete_after["hour"] = unscheduledTask.complete_after.slice(11, 13)
                unscheduledTaskObj.complete_after["minute"] = unscheduledTask.complete_after.slice(14, 16)
                unscheduledTaskObj.complete_after["second"] = unscheduledTask.complete_after.slice(17, 19)
                unscheduledTaskObj.complete_after["second_of_day"] = (unscheduledTaskObj.complete_after["hour"] * 60 * 60) + (unscheduledTaskObj.complete_after["minute"] * 60)
            }

            unscheduledTaskObj.complete_before = {}
            if (unscheduledTask.complete_before != null) {
                unscheduledTaskObj.complete_before["hour"] = unscheduledTask.complete_before.slice(11, 13)
                unscheduledTaskObj.complete_before["minute"] = unscheduledTask.complete_before.slice(14, 16)
                unscheduledTaskObj.complete_before["second"] = unscheduledTask.complete_before.slice(17, 19)
                unscheduledTaskObj.complete_before["second_of_day"] = (unscheduledTaskObj.complete_before["hour"] * 60 * 60) + (unscheduledTaskObj.complete_before["minute"] * 60)
            }
                       
            unscheduledTaskObj.outpost_setting = {}
            unscheduledTaskObj.outpost_setting.drop_days = unscheduledTask.drop_days

            routeTemplateTasks[task_id] = unscheduledTaskObj
            launchTaskIds.push(task_id)

        })

        routeTemplate[0] = {
            id: 0,
            title: 'Unscheduled Tasks:',
            taskIdsArray: launchTaskIds,
        }
        // Assumes you never get back an id:0 in response.route_templates
        const rtColumnIds = [0, ...routeTemplates.map(rt => rt.id)]

        const initialData = {
            tasks: routeTemplateTasks,
            columns: routeTemplate,
            columnOrder: rtColumnIds
        }

        setData(initialData);

    }, [unscheduledTasks, routeTemplates]);
    if (!data) {
        return null;
    }

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result

        if (!destination) {
            return
        }
        if (
            destination.droppableId === source.draggableId &&
            destination.index === source.index
        ) {
            return
        }

        const start = data.columns[source.droppableId]
        const finish = data.columns[destination.droppableId]

        if (start === finish) {
            const newTemplateTasks = Array.from(start.taskIdsArray)
            newTemplateTasks.splice(source.index, 1)
            newTemplateTasks.splice(destination.index, 0, draggableId)

            const newColumn = {
                ...start,
                taskIdsArray: newTemplateTasks
            }

            const newState = {
                ...data,
                columns: {
                    ...data.columns,
                    [newColumn.id]: newColumn,
                }
            }
            setData(newState)

            DnDPatchRequest(destination.droppableId, newState)
            return
        }

        const startTaskTemplates = Array.from(start.taskIdsArray);

        startTaskTemplates.splice(source.index, 1);

        const newStart = {
            ...start,
            taskIdsArray: startTaskTemplates,
        };

        const finishTaskTemplates = Array.from(finish.taskIdsArray);
        finishTaskTemplates.splice(destination.index, 0, draggableId);

        const newFinish = {
            ...finish,
            taskIdsArray: finishTaskTemplates,
        };

        const newState = {
            ...data,
            columns: {
                ...data.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };
        setData(newState);

        DnDPatchRequest(destination.droppableId, newState)
        DnDPatchRequest(source.droppableId, newState)
        setRefreshReminderMsg("REMEMBER TO REFRESH! - Payout Amounts may have changed during drag and drop. Refresh to show the correct payout amount value.")
        return
    }

    async function DnDPatchRequest(id, state) {
        if (id != 0) {
            const idNumber = parseInt(id)
            const orderedTaskIds = []
            state.columns[idNumber].taskIdsArray.map((taskId) => {
                orderedTaskIds.push(parseInt(taskId))
            })

            const outpost_setting_ids = { outpost_setting_ids: orderedTaskIds }

            let response = await fetch(`/api/route_templates/${idNumber}`, {

                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(outpost_setting_ids),
            })
            let result;
            result = await response.json()
        }

    }

    function updateRouteTemplate() {
        fetchRouteContainerAPI();
        updateUnscheduledTasks();
    }

    //START:weekday display logic 

    function displayDays(daysOfWeek) {
        const WEEKDAY = {
            "sunday": 0,
            "monday": 1,
            "tuesday": 2,
            "wednesday": 3,
            "thursday": 4,
            "friday": 5,
            "saturday": 6
        }
        if (daysOfWeek === null) {
            return daysOfWeek;
        } else if (daysOfWeek.length == 1) {
            return daysOfWeek[0].slice(0, 3).charAt(0).toUpperCase() + daysOfWeek[0].slice(0, 3).slice(1)
        } else {
            if (daysOfWeek.length > 2) {
                for (let i = 1; i < daysOfWeek.length; i++) {
                    if (WEEKDAY[daysOfWeek[i]] - WEEKDAY[daysOfWeek[i - 1]] !== 1) {
                        return daysOfWeek.map(item => item.slice(0, 3).charAt(0).toUpperCase() + item.slice(0, 3).slice(1)).join(", ");
                    }
                }
                return `${daysOfWeek[0].slice(0, 3).charAt(0).toUpperCase() + daysOfWeek[0].slice(0, 3).slice(1)} - ${daysOfWeek[daysOfWeek.length - 1].slice(0, 3).charAt(0).toUpperCase() + daysOfWeek[daysOfWeek.length - 1].slice(0, 3).slice(1)}`;
            } else {
                return daysOfWeek.map(item => item.slice(0, 3).charAt(0).toUpperCase() + item.slice(0, 3).slice(1)).join(", ");
            }
        }
    }
    //END:weekday display logic 
    // separating the array of columns at index position 0 to be the UnscheduledTaskContainer (ust) component. The rest fall into RouteContainer component.
    const ust = data.columnOrder[0]
    const ust_column = data.columns[ust];
    const ust_tasks = ust_column.taskIdsArray.map(taskId => data.tasks[taskId]);
    return (
        <DragDropContext onDragEnd={onDragEnd} >
            <div className='row bg-white m1 p1 borderRadius-s boxShadow'>
                {routeCreationMsg.length == 0 ? <></> : <div className={routeCreationMsg[0] ? 'alert alert-success' : 'alert alert-danger'} role="alert">{routeCreationMsg[1]}</div>}
                {refreshReminderMsg == "" ? <></> : <div className={'alert alert-warning'} role="alert">{refreshReminderMsg}</div>}
                {
                store.id != null ?
                    <div style={{ display: "flex", flexWrap: "wrap" }} >
                        <div className="fontSize-xxl ptrb1"  style={{ paddingLeft: "13px"}}>{store.name}</div>
                        <div className=" bg-white boxShadow borderRadius-s p1 m1 ">
                            <div className="fontSize-l fontWeight-5 c-silver ">Gravy ID: </div>
                            <div className="fontSize-l fontWeight-6">{store.gravy_id}</div>
                        </div>
                        <div className=" bg-white boxShadow borderRadius-s p1 m1 ">
                            <div className="fontSize-l fontWeight-5 c-silver ">Time Zone: </div>
                            <div className="fontSize-l fontWeight-6 ">{store.time_zone}</div>
                        </div>
                        <div className=" bg-white boxShadow borderRadius-s p1 m1 ">
                            <div className="fontSize-l fontWeight-5 c-silver ">Address: </div>
                            <div className="fontSize-l fontWeight-6 ">
                            {store.salesforce_account ? `${store.salesforce_account.shippingstreet} ${store.salesforce_account.shippingcity}, ${store.salesforce_account.shippingstate} ${store.salesforce_account.shippingpostalcode}` : null}
                            </div>
                        </div>
                    </div> : <></>
                }
                {upcomingLaunchesLoading 
                    ? <div className="label-large loader" data-loading><span className="glyphicon glyphicon-refresh rotating"></span> Loading Upcoming Launches...</div>
                    : <UpcomingLaunches displayDays={displayDays} upcomingLaunches={upcomingLaunches} store={store}></UpcomingLaunches>
                }
                <div style={{ display: "flex", flexDirection: "row", margin: "2% 0 0 0" }}>
                    <h4 style={{ marginRight: "106px", marginLeft: "14px"}}>Unscheduled Tasks:</h4>
                    <h4>Reccuring Route Templates:</h4>
                </div>
                <div style={{ display: "flex" }}>
                    {unscheduledTasksLoading
                        ?   <div className="label-large loader" data-loading><span className="glyphicon glyphicon-refresh rotating"></span> Loading Unscheduled Tasks...</div>
                        :   <UnscheduledTaskContainer key={ust_column.id} column={ust_column} tasks={ust_tasks} displayDays={displayDays} updateRouteTemplate={updateRouteTemplate} ></UnscheduledTaskContainer>
                    }                    
                   {unscheduledRouteContainerLoading
                    ? <div className="label-large loader" data-loading><span className="glyphicon glyphicon-refresh rotating"></span> Loading Route Templates...</div>
                    : <div  style={{ display: "flex", flexWrap: "wrap", alignContent: "start"}}>
                        {
                        data.columnOrder.map((columnId, index) => {
                            if (index !== 0) {
                                const column = data.columns[columnId];
                                const tasks = column.taskIdsArray.map(taskId => data.tasks[taskId]);
                                const count = column.taskIdsArray.length


                                return <RouteContainer setRouteCreationMsg={setRouteCreationMsg} locations={locations} drivers={drivers} key={column.id} column={column} tasks={tasks} count={count} displayDays={displayDays} updateRouteTemplate={updateRouteTemplate} weekday={weekday}></RouteContainer>


                            }
                        })
                        }
                    </div>
                    }

                </div>
            </div>
        </DragDropContext>
    )
}
